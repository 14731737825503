.live-section {
    font-family: 'Inter', sans-serif;
    padding: 15px 0px 15px 15px;
    margin-top: 0px;
    height: 300px;
    border-bottom:solid;
    border-width: 1px;
}

.live-title {
    margin-top: 0;
    font-weight: 200;
}

.live-list{
    display:flex;
    overflow:auto;
    overflow-y:hidden;
    width:auto;
    
}

.left-container{
    display:flex;
    flex-direction: column;
    border-color:green;
    width:100%;
    overflow:auto;
    max-height:100vh;
}

.left-container::-webkit-scrollbar{
    display:none;
}

.coin-info{
    font-family: 'Inter', sans-serif;
    padding: 15px;
    border:solid;
    border-width:1px;
    border-radius:10px;
    padding:15px;
    margin-left:15px;
    margin-top:25px;
    margin-right:15px;
    background-color:rgb(187, 204, 201);
    min-height:300px;
    overflow:auto;
    margin-bottom:15px;
    max-width:900px;
    
}

.coin-info-name{
    font-weight:300;
}

.coin-info-details{
    font-weight:200;
}